<template>
  <!--    Add new admin-->
  <b-card-code>
    <div>
      <!-- trigger button -->
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-edit
          variant="danger"
          class="btn-sm"
          @click="resetModal"
        >
          {{ $t('Add') }}
        </b-button>
      </div>

      <b-modal
        id="modal-edit"
        :ok-title=" id ? $t('update'):$t('save_info')"
        ok-variant="danger"
        :cancel-title="$t('cancel')"
        centered
        size="lg"
        :title="id ? $t('update') : $t('Add')"
        @ok.prevent="validationForm"
      >
        <validation-observer ref="addAdminRules">
          <b-form class="form_custom">
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    vid="email"
                    rules="required|email"
                  >
                    <label
                      for="email"
                      :class="errors.length > 0 ? 'text-danger': null"
                    >{{ $t('name') }} <span class="text-danger">(*)</span></label>
                    <b-form-input
                      id="email"
                      v-model="email"
                      :state="errors.length > 0 ? false:null"
                      placeholder="abcd@gmail.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('permission_access')"
                    rules="required"
                  >
                    <label
                      for="role"
                      :class="errors.length > 0 ? 'text-danger': null"
                    >{{ $t('permission_access') }} <span class="text-danger">(*)</span></label>
                    <b-form-select
                      v-model="roleId"
                      :state="errors.length > 0 ? false:null"
                      :options="roleOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    v-if="!id"
                    #default="{ errors }"
                    name="Mật khẩu"
                    rules="required|min:6"
                    vid="password"
                  >
                    <label
                      for="password"
                      :class="errors.length > 0 ? 'text-danger': null"
                    >{{ $t('pass') }} <span class="text-danger">(*)</span></label>

                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="password"
                        v-model="password"
                        name="password"
                        :state="errors.length > 0 ? false:null"
                        :type="passwordFieldType"
                        :placeholder="$t('pass')"
                        style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <validation-provider
                    v-if="id"
                    #default="{ errors }"
                    name="Mật khẩu"
                    rules="min:6"
                    vid="password"
                  >
                    <label
                      for="password"
                      :class="errors.length > 0 ? 'text-danger': null"
                    >{{ $t('pass') }} <span class="text-danger">(*)</span></label>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="password"
                        v-model="password"
                        name="password"
                        :state="errors.length > 0 ? false:null"
                        :type="passwordFieldType"
                        :placeholder="$t('pass')"
                        style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    v-if="!id"
                    #default="{ errors }"
                    name="Xác nhận mật khẩu"
                    rules="required|min:6|confirmed:password"
                  >
                    <label
                      for="password-comfirmation"
                      :class="errors.length > 0 ? 'text-danger': null"
                    >{{ $t('confirm_pass') }} <span class="text-danger">(*)</span></label>

                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="password-comfirmation"
                        v-model="passwordConfirmation"
                        :state="errors.length > 0 ? false:null"
                        :type="passwordFieldType"
                        :placeholder="$t('pass')"
                        style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <validation-provider
                    v-if="id"
                    #default="{ errors }"
                    name="Xác nhận mật khẩu"
                    rules="min:6|confirmed:password"
                  >
                    <label
                      for="password-comfirmation"
                      :class="errors.length > 0 ? 'text-danger': null"
                    >{{ $t('confirm_pass') }} <span class="text-danger">(*)</span></label>

                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="password-comfirmation"
                        v-model="passwordConfirmation"
                        :state="errors.length > 0 ? false:null"
                        :type="passwordFieldType"
                        :placeholder="$t('pass')"
                        style="border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important;"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <div class="form-label-group d-flex">
                  <b-form-checkbox
                    v-model="status"
                    class="custom-control-danger"
                    name="is_active"
                    switch
                  />
                  <div>{{ $t('status') }}</div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>

    <!--    Filter-->
    <div>
      <b-row class="mb-1">
        <b-col md="6">
          <b-button
            variant="outline-danger"
            class="position-absolute position-bottom-0 btn-sm"
            :disabled="!deletedIds.length"
            @click="executeDelete()"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
            {{ $t('delete') }}
          </b-button>
        </b-col>
        <b-col md="3">
          <label for="">
            {{ $t('permission_access') }}
          </label>
          <b-form-select
            v-model="filter.role"
            :options="filter.roleOptions"
            @change="val => changeFilterRole(val)"
          />
        </b-col>
        <b-col md="3">
          <label for="">
            {{ $t('Search') }}
          </label>
          <b-form-input
            v-model="filter.key"
            :placeholder="$t('search_key')"
            @change="loadItems"
          />
        </b-col>
      </b-row>
    </div>

    <!--    Table admins-->
    <div
      class="position-relative table-responsive"
    >
      <table
        role="table"
        aria-busy="false"
        aria-colcount="5"
        class="table b-table"
      >
        <thead
          role="rowgroup"
        >
          <tr
            role="row"
          >
            <th
              role="columnheader"
              aria-colindex="0"
              width="10"
            >
              <div>
                <b-form-checkbox
                  v-model="isDeletedAll"
                  class="custom-control-danger"
                  name="check-button"
                  @change="val => deleteAll(val)"
                />
              </div>
            </th>
            <th
              role="columnheader"
              aria-colindex="1"
              width="10"
              class="text-center"
            >
              <div>Stt</div>
            </th>
            <th
              role="columnheader"
              aria-colindex="4"
              :aria-sort="sortDirection"
              @click="changeSortDirection"
            >
              <div>{{ $t('name_q') }}</div>
            </th>
            <th
              role="columnheader"
              aria-colindex="2"
              class="text-center"
            >
              <div>{{ $t('permission_access') }}</div>
            </th>
          </tr>
        </thead>
        <template v-if="isLoading">
          <tr
            v-for="(item) in rows"
          >
            <td colspan="1">
              <b-skeleton animation="fade" />
            </td>
            <td colspan="2">
              <b-skeleton animation="fade" />
            </td>
            <td colspan="1">
              <b-skeleton animation="fade" />
            </td>
          </tr>
        </template>

        <tr
          v-for="(row, index) in rows"
          v-else
          :id="row.id"
          :key="row.id"
          role="row"
        >
          <td
            role="cell"
          >
            <div
              class="media"
            >
              <div
                class="media-body"
              >
                <b-form-checkbox
                  v-model="deletedIds"
                  :value="row.id"
                  class="custom-control-danger"
                  name="check-button"
                />
              </div>
            </div>
          </td>
          <td
            role="cell"
            class="text-center"
          >
            <span
              class="text-black"
            >
              {{ index + 1 }}
            </span>
          </td>
          <td
            role="cell"
          >
            <div
              class="media"
            >
              <div
                class="media-body"
              >
                <span
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.top="row.status? 'Đang hoạt động' : 'Ngưng hoạt động'"
                  variant="outline-primary"
                  style="font-size: 8px"
                  class="mr-1"
                >
                  <i
                    :class="row.status ? 'text-success' : 'text-danger '"
                    class="fas fa-circle xs"
                  />
                </span>
                <span
                  v-b-modal.modal-edit
                  @click="handleEdit(row)"
                >

                  {{ row.email }}
                </span>
              </div>
            </div>
          </td>
          <td
            role="cell"
            class="text-center"
          >
            <div
              class=""
            >
              <span
                class="align-text-top text-capitalize"
              >
                {{ row.roles.length ? row.roles[0].name : '' }}
              </span>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <!--    Pagination-->
    <b-pagination
      v-model="currentPage"
      :total-rows="total"
      :per-page="perPage"
      first-number
      last-number
      class="pagination-danger justify-content-end mt-2"
      @input="val => changePage(val)"
    >
      <template #prev-text>
        <feather-icon
          size="18"
          icon="ChevronLeftIcon"
        />
      </template>
      <template #next-text>
        <feather-icon
          size="18"
          icon="ChevronRightIcon"
        />
      </template>
    </b-pagination>
  </b-card-code>
</template>

<script>
import {
  BFormCheckbox,
  BPagination,
  BRow,
  BCol,
  BFormSelect,
  BButton,
  BModal,
  VBModal,
  BFormGroup,
  BForm,
  BFormInput,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
  BSkeleton,
  VBTooltip,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import draggable from 'vuedraggable'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

export default {
  components: {
    BFormCheckbox,
    BSkeleton,
    BCardCode,
    draggable,
    BPagination,
    BRow,
    BCol,
    BFormSelect,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormGroup,
    BForm,
    BFormInput,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      total: 0,
      rows: [],
      currentPage: 1,
      perPage: 10,
      filter: {
        role: 'all',
        key: '',
        roleOptions: [
          { value: 'all', text: this.$t('all') },
        ],
      },
      roleOptions: [],
      id: '',
      email: '',
      roleId: '',
      password: '',
      passwordConfirmation: '',
      status: true,
      deletedIds: [],
      isDeletedAll: false,
      sortBy: 'order',
      sortDirection: 'none',
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    deletedIds: {
      handler(value) {
        this.isDeletedAll = value.length && this._.difference(this.rows.map(v => v.id), value).length === 0
      },
    },
  },
  created() {
    this.loadRoleItems()
    this.loadItems()
  },
  methods: {
    changeFilterRole(val) {
      this.filter.role = val
      this.loadItems()
    },
    changePage(val) {
      this.currentPage = val
      this.loadItems()
    },
    deleteAll(val) {
      if (val) {
        this.deletedIds = this.rows.map(v => v.id)
      } else {
        this.deletedIds = []
      }
    },
    executeDelete() {
      this.$swal({
        title: this.$t('title_confirm_delete'),
        text: this.$t('text_confirm_delete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          this.$http.delete('/users', {
            data: {
              ids: this.deletedIds,
            },
          })
          // eslint-disable-next-line no-unused-vars
            .then(res => {
              this.$bvToast.toast(this.$t('message.delete_success'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'success',
                solid: false,
              })
              this.loadItems()
              // eslint-disable-next-line no-unused-vars
            }).catch(e => {
              this.$bvToast.toast(this.$t('message.delete_err'), {
                title: this.$t('message.notify'),
                icon: 'BellIcon',
                variant: 'danger',
                solid: false,
              })
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    loadRoleItems() {
      this.$http.get('/roles', {
        params: {
          perPage: 1000,
          page: 1,
        },
      })
        .then(res => {
          this.total = res.data.total
          res.data.data.forEach(item => {
            this.roleOptions.push({
              value: item.id,
              text: item.name,
            })
            this.filter.roleOptions.push({
              value: item.id,
              text: item.name,
            })
          })
        })
        .catch()
        .finally(() => {
        })
    },
    loadItems() {
      this.isLoading = true
      this.$http.get('/users', {
        params: {
          page: this.currentPage,
          filter_role: this.filter.role,
          key: this.filter.key,
          per_page: this.perPage,
          order_by: this.sortBy,
          order_direction: this.sortDirection === 'descending' ? 'desc' : 'asc',
        },
      })
        .then(res => {
          this.total = res.data.total
          this.pageLength = res.data.per_page
          this.rows = res.data.data
        }).catch()
        .finally(() => {
          this.isLoading = false
        })
    },
    getFirstTwoCharacters(email) {
      const username = email.split('@')[0] // Tách tên người dùng từ địa chỉ email

      if (username.length >= 2) {
        return username.substring(0, 2) // Lấy 2 ký tự đầu tiên của tên
      }
      return username // Trả về toàn bộ tên nếu có ít hơn 2 ký tự
    },
    validationForm() {
      this.$refs.addAdminRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          if (!this.id) {
            this.$http.post('/users', {
              email: this.email,
              role_id: this.roleId,
              name: this.getFirstTwoCharacters(this.email),
              password: this.password,
              password_confirmation: this.passwordConfirmation,
              status: this.status ? 1 : 0,
            })
            // eslint-disable-next-line no-unused-vars
              .then(res => {
                this.$bvToast.toast(this.$t('message.update_success'), {
                  title: this.$t('message.notify'),
                  icon: 'BellIcon',
                  variant: 'success',
                  solid: false,
                })
                this.$bvModal.hide('modal-edit')
                this.loadItems()
                this.resetModal()
                // eslint-disable-next-line no-unused-vars
              }).catch(e => {
                let errorMsg = {}
                if (e.response.status === 422) {
                  errorMsg = e.response.data.errors
                } else {
                  errorMsg.name = [e.response.data.error]
                }
                this.$refs.addAdminRules.setErrors(errorMsg)
                this.$bvToast.toast(this.$t('message.update_err'), {
                  title: this.$t('message.notify'),
                  icon: 'BellIcon',
                  variant: 'danger',
                  solid: false,
                })
              }).finally(() => {
                this.isLoading = false
              })
          } else {
            this.$http.put(`/users/${this.id}`, {
              email: this.email,
              role_id: this.roleId,
              password: this.password,
              password_confirmation: this.passwordConfirmation,
              status: this.status ? 1 : 0,
            })
            // eslint-disable-next-line no-unused-vars
              .then(res => {
                this.$bvToast.toast(this.$t('message.update_success'), {
                  title: this.$t('message.notify'),
                  icon: 'BellIcon',
                  variant: 'success',
                  solid: false,
                })
                this.$bvModal.hide('modal-edit')
                this.loadItems()
                // eslint-disable-next-line no-unused-vars
              }).catch(e => {
                let errorMsg = {}
                if (e.response.status === 422) {
                  errorMsg = e.response.data
                } else {
                  errorMsg.name = [e.response.data.error]
                }
                this.$refs.addAdminRules.setErrors(errorMsg)
                this.$bvToast.toast(this.$t('message.update_err'), {
                  title: this.$t('message.notify'),
                  icon: 'BellIcon',
                  variant: 'danger',
                  solid: false,
                })
              }).finally(() => {
                this.isLoading = false
              })
          }
        }
      })
    },
    onEnd(value) {
      if (value.oldIndex === value.newIndex) return
      const newOrder = this.rows[value.newIndex].order
      const oldOrder = this.rows[value.oldIndex].order
      if (newOrder === oldOrder) return

      this.changeOrder(oldOrder, newOrder)
    },
    changeOrder(oldOrder, newOrder) {
      this.isLoading = true
      this.$http.put('/users', { oldOrder, newOrder })
      // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$bvToast.toast(this.$t('message.update_success'), {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'success',
            solid: false,
          })
          // eslint-disable-next-line no-unused-vars
        }).catch(e => {
          this.$bvToast.toast(this.$t('message.update_err'), {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'danger',
            solid: false,
          })
        }).finally(() => {
          this.isLoading = false
          this.loadItems()
        })
    },
    changeSortDirection() {
      this.sortBy = 'email'
      if (this.sortDirection === 'none') {
        this.sortDirection = 'ascending'
      } else if (this.sortDirection === 'ascending') {
        this.sortDirection = 'descending'
      } else {
        this.sortBy = 'order'
        this.sortDirection = 'none'
      }
      this.loadItems()
    },
    handleEdit(item) {
      this.id = item.id
      this.email = item.email
      this.roleId = item.roles[0].id
      this.password = ''
      this.passwordConfirmation = ''
    },
    resetModal() {
      this.id = ''
      this.email = ''
      this.roleId = ''
      this.password = ''
      this.passwordConfirmation = ''
      this.status = true
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
